import { template as template_68032c43925b4392b0d826e50d265a04 } from "@ember/template-compiler";
const FKText = template_68032c43925b4392b0d826e50d265a04(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
